import { AuthModel } from './auth.model';
import { AddressModel } from './address.model';
import { SocialNetworksModel } from './social-networks.model';

export class UserModel extends AuthModel {
  id: number;
  username: string;
  password: string;
  fullname: string;
  email: string;
  pic: string;
  roles: string[];
  occupation: string;
  companyName: string;
  phone: string;
  address?: AddressModel;
  socialNetworks?: SocialNetworksModel;
  // personal information
  firstname: string;
  lastname: string;
  website: string;
  // account information
  language: string;
  timeZone: string;
  communication: {
    email: boolean,
    sms: boolean,
    phone: boolean
  };
  subscription: string;
  subscription_until: string;
  subscription_type?: 'apple_iap' | 'stripe' | 'paypal';
  // email settings
  emailSettings: {
    emailNotification: boolean,
    sendCopyToPersonalEmail: boolean,
    activityRelatesEmail: {
      youHaveNewNotifications: boolean,
      youAreSentADirectMessage: boolean,
      someoneAddsYouAsAsAConnection: boolean,
      uponNewOrder: boolean,
      newMembershipApproval: boolean,
      memberRegistration: boolean
    },
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: boolean,
      tipsOnGettingMoreOutOfKeen: boolean,
      thingsYouMissedSindeYouLastLoggedIntoKeen: boolean,
      newsAboutMetronicOnPartnerProductsAndOtherServices: boolean,
      tipsOnMetronicBusinessProducts: boolean
    }

  };

  activity_level: number;
  age: number;
  city: string;
  created_at: string;
  dob: string;
  gender: string;
  had_trial: number;
  is_deactivated: boolean;
  is_verified: boolean;
  last_login: string;
  size: number;
  weight: number;
  zip: string;

  setUser(user: any) {
    this.id = user.id;
    this.username = user.first_name || '';
    this.fullname = user.first_name + ' ' + user.name || '';
    this.email = user.email || '';
    this.pic = user.image || './assets/media/users/default.jpg';
    this.roles = [user.role] || [];
    this.address = user.address_1;
    this.activity_level = user.activity_level;
    this.age = user.age;
    this.city = user.city;
    this.created_at = user.created_at;
    this.dob = user.dob;
    this.gender = user.gender;
    this.had_trial = user.had_trial;
    this.is_deactivated = user.is_deactivated;
    this.is_verified = user.is_verified;
    this.last_login = user.last_login;
    this.size = user.size;
    this.weight = user.weight;
    this.zip = user.zip;
    this.subscription_until = user.subscription_until;
    this.subscription = user.subscription;
    this.subscription_type = user.subscription_type;
  }
}
