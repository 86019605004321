import { HttpResponse } from "@angular/common/http";

export class IPaging {
    currentPage: number;
    lastPage: number;

    prevPageUrl: string;
    nextPageUrl: string;

    perPage: number;
    total: number;
    filter?: string;
}
export interface IPagingResponse<T> {
    items: T[],
    paging: IPaging,
}
export enum ResponseStatus {
    success = 'success',
    error = 'error'
}
export interface IAPIResponse<T> {
    status: ResponseStatus,
    message: string,
    data: T[],
    error: any[]
}

export abstract class HttpHelper {
    public static getHeader(response: HttpResponse<any>, key: string): string {
        return response.headers.get(key);
    }

    public static extractResult(response: IAPIResponse<any>): boolean {
        if (response?.status === ResponseStatus.success) {
            return true;
        } else {
            throw response.message;
        }
    }

    public static extractData<T>(response: IAPIResponse<T>): T {
        if (response?.status === ResponseStatus.success && response?.data[0]) {
            let result = response.data[0];
            return result;
        } else {
            throw response.message;
        }
    }

    public static extractCollection<T>(response: IAPIResponse<T>): T[] {
        if (response?.status === ResponseStatus.success && response?.data) {
            let result = response.data;
            return result;
        } else {
            throw response.message;
        }
    }

    public static extractPaging<T>(response: HttpResponse<any>, filter: string = ''): IPagingResponse<T> {
        if (response?.body?.status === ResponseStatus.success) {
            let items = this.extractCollection<T>(response?.body);
            return {
                items: items,
                paging: {
                    currentPage: +this.getHeader(response, 'current_page'),
                    lastPage: +this.getHeader(response, 'last_page'),

                    prevPageUrl: this.getHeader(response, 'prev_page_url'),
                    nextPageUrl: this.getHeader(response, 'next_page_url'),

                    perPage: +this.getHeader(response, 'per_page'),
                    total: +this.getHeader(response, 'total'),

                    filter: filter
                }
            };
        }
    }
}