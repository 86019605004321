import { Injectable } from '@angular/core';
import { AuthModel } from 'src/app/modules/auth/_models/auth.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  removeAuthFromLocalStorage() {
    localStorage.removeItem(this.authLocalStorageToken);
  }

  getAccessToken(): string {
    return this.getAuthFromLocalStorage()?.authToken;
  }
}
