import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/auth';
import { LocalStorageService } from '../services/local-storage.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  // constructor(@Inject(PLATFORM_ID) private platformId: Object,
  //   private authService: AuthService) { }
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private lsService: LocalStorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accessToken = this.lsService.getAccessToken();
    if (accessToken) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + accessToken).set('Accept-Language', 'de') });
    }

    return next.handle(request);
  }
}
