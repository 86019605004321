import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { AuthModel } from '../../_models/auth.model';
import { map } from 'rxjs/operators';
import { HttpHelper, IAPIResponse } from 'src/app/core/helpers/http.helper';

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(email: string, password: string): Observable<AuthModel> {
    return this.http.post<IAPIResponse<AuthModel>>(`/login`, { email, password })
      .pipe(map((data) => {
        let auth = HttpHelper.extractData(data);
        let result = new AuthModel();
        result.setAuth(auth)
        return result;
      }));
  }

  getUserByToken(): Observable<UserModel> {
    return this.http.get<IAPIResponse<UserModel>>(`/user`)
      .pipe(map((data) => {
        let userData = HttpHelper.extractData(data);
        let result = new UserModel();
        result.setUser(userData)
        return result;
      }));
  }
}
