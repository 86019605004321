export class AuthModel {

  authToken: string;
  refreshToken: string;
  expiresIn: Date;

  setAuth(auth: any) {
    this.authToken = auth.access_token;
    this.refreshToken = auth.refresh_token;
    this.expiresIn = auth.expires_in;
  }
}
